.dropdown-toggle::after {
    display: none;
}

.navbar-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.navbar {
    background-color: var(--primary-app-color);
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}
  
.menu-icon {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    /* background-color: #2C3E50; */
    background-color: var(--primary-app-color);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: whitesmoke;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: black;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #2C3E50;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-dropdown {
    background-color: var(--secondary-app-color);
}