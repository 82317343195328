.user-table {
    width: 90%;
    font-size: 1em;
    border-collapse: collapse;
}
  
.user-table th,
.user-table td {
    border: 1px solid var(--secondary-app-color);
    padding: 8px;
    text-align: center;
    /* background-color: #2C3E50; */
    background-color: white;
    color: black;
}
  
.user-searchbar{
    padding: 10px; 
    border: none;
    border-radius: 5px;
    max-width: 90%; 
}

.table-container {
    background-color: var(--secondary-app-color);
    color: black;
    width: 60%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding-bottom: 30px;
}
  
.sort-button {
    padding: 10px;
}

.close-button {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    justify-content: right;
    float: right;
    color: black;
}

/* .authorize-button,
.decline-button {
    width: 80px;
    background-color: var(--success-button-color);
    color: white;
    border: none;
    padding: 8px;
    text-align: center; 
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
} */

.authorize-button {
    background-color: var(--success-button-color);
}

.authorize-button:hover{
    background-color: var(--success-button-hover-color);
}

.decline-button,
.delete-button {
    background-color: var(--danger-button-color); 
}

.decline-button:hover,
.delete-button:hover {
    background-color: var(--danger-button-hover-color); 
}

.edit-button {
    background-color: var(--primary-button-color);
}

.edit-button:hover {
    background-color: var(--primary-button-hover-color);
}

.edit-button,
.delete-button,
.authorize-button,
.decline-button {
    text-align: center; 
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    display: inline-flex;
}

@media (max-width: 600px) {
    .user-table .email-column {
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    .table-container {
        width: 100%; /* Reduce the width for smaller screens */
        /* padding: 1rem;  */
    }
    
    .user-table {
    font-size: 0.6em; /* Reduce the font size for smaller screens */
    }
    
    .user-table th,
    .user-table td {
        padding: 0.2em; 
    }
}

.th-clickable {
    cursor: pointer;
}

.th-clickable:hover {
    background-color: #eae8e2;
}

.checkbox-label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.checkbox-label:hover {
    background-color: #eae8e2;
}