.Calendar {
    color: black;
}
  
span.fc-icon-chevron-left {
    margin-left: 0px;
}

span.fc-icon-chevron-right {
    margin-left: 0px;
}

.Calendar {
    color: black;
    height: auto !important;
}

.dropdowns {
    z-index: 20;
}

span.fc-icon-chevron-left {
    margin-left: 0px;
}

span.fc-icon-chevron-right {
    margin-left: 0px;
}

.fc-event {
    --fc-event-bg-color: transparent;
    --fc-event-border-color: transparent;
}

.fc-daygrid-dot-event {
    display: block;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 0px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 0px;
}

.fc-daygrid-day-number, .fc-col-header-cell-cushion {
    text-decoration: none;
    color:var(--primary-button-color);
}

.fc-list-day-text {
    color: black;
    text-decoration: none;
}

.fc-list-day-side-text {
    color: black;
    text-decoration: none;
}

.block-time-button,
.new-meeting-button {
    border: none;
    background-color: var(--success-button-color);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    width: 320px;
    color: white;
    text-align: center;
    transition: background-color 0.15s;
}

.new-meeting-button:hover {
    background-color: var(--success-button-hover-color);
}

.block-time-button {
    background-color: var(--cancel-button-color);
}

.block-time-button:hover {
    background-color: var(--cancel-button-hover-color);
}

.fc-view-harness,
.fc-view-harness-active {
    min-height: 500px !important;
}

.validation-message{
    color: red;
}

.fc-toolbar,
.fc-header-toolbar {
    margin-bottom: 0.5em !important;
}

.fc-popover {
    z-index: 10 !important;
}

@media (max-width: 1000px) {
    .fc
    .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: center;
    }

    .fc-header-toolbar
    .fc-toolbar-chunk {
        display: block;
    }

    .fc-button-group {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.fc-button-primary {
    background-color: var(--primary-button-color);
    border: none;
}

.fc-button-primary:hover {
    background-color: var(--primary-button-hover-color) !important;
}

/* .fc-button-primary:focus,
.fc-button-active::selection {
    background-color: var(--primary-button-active-color) !important;
} */

:root {
    --fc-button-active-bg-color: var(--primary-button-active-color);
}

.tooltip {
    z-index: 9 !important;
}
