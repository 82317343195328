:root {
  --primary-app-color: #2F5266;
  --secondary-app-color: #D2D0CA;

  --primary-button-color: #2F5266;
  --primary-button-hover-color: #457896;
  --primary-button-active-color: #457896;

  --cancel-button-color: #7a7a7b;
  --cancel-button-hover-color: #565e64;

  --success-button-color: #6eba80;
  --success-button-hover-color: #52895f;

  --danger-button-color: #d54c4e;
  --danger-button-hover-color: #ae3d3f;
}

.App {
  /* text-align: center;
  display: flex; */
  min-height: 100vh;
  /* align-items: center;
  justify-content: center;
  color: white; */
  background-color: white;
  margin-top: 100px;
}

.auth-form-container,
.user-settings-form,
.login-form,
.register-form,
.modal-container,
.button-container {
  display: flex;
  flex-direction: column;
  /* background-color: #2C3E50; */
  background-color: var(--secondary-app-color);
  color: black;
}

@media screen and (min-width: 400px) {
  .auth-form-container,
  .user-settings-form,
  .login-form,
  .register-form {
    padding: 5px;
    border-radius: 5px;
    margin: 1rem;
  }
  
  .some-class {
    padding: 0.5rem;
  }

}

@media only screen and (max-width: 600px) {

  .auth-form-container,
  .user-settings-form,
  .login-form,
  .register-form {
    padding: 5px;
    border-radius: 5px;
    margin: 1rem;
  }
  
  .some-class {
    padding: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  width: 100%;
}

button {
  border: none;
  background-color: var(--primary-button-color);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  transition: background-color 0.15s;
}

button:hover {
  background-color: var(--primary-button-hover-color);
}

.cancel-button {
  border: none;
  background-color: var(--cancel-button-color);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  transition: background-color 0.15s;
}

.cancel-button:hover {
  background-color: var(--cancel-button-hover-color);
}

.link-button {
  background-color: transparent;
  color: black;
  text-decoration: underline;
  padding: 0px;
  transition: background-color .15s;
  transition: color .15s;
}

.link-button:hover {
  background-color: transparent;
  color: grey;
}

/* span {
  margin-left: 16px;
} */

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Ensure it's on top of the form */
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  /* Light gray border */
  border-top: 4px solid #3498db;
  /* Blue border for spinning effect */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  /* Rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.password-toggle-button {
  color: black;
  background: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

#password {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#passwordIcon {
  font-size: 20px;
}

.topnav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.tooltip {
  --bs-tooltip-max-width: 400px !important;
  --bs-tooltip-font-size: 1rem !important;
}

.tippy-box[data-theme~='forest'] {
  background-color: #2c3e50; /* your custom styling */
  color: white; /* your custom styling */
  /* add more custom styles if needed */
}